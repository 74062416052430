import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { configbyOrigin } from '../../utils/helpers';
import { queryVms } from '../../utils/vmsQuery';
import { byOrigin as brands } from '../../brands/brands';
import UserContext from '../contexts/UserContext';

const LogoImageFromVMS = ({ origin, classes }) => {
	const [imageUri, setImageUri] = useState('')
	const { configData } = useContext(UserContext);
	const fallbackImage = brands(origin) ? brands(origin).images.icon.backgroundImage : '';
	let data, label;
	useEffect(() => {
		const brand = configbyOrigin(origin, configData?.origins)   // Use the origins from config for brand guid
		const getDataFromVMS = async () => {
			if (!brand) {
				setImageUri(fallbackImage)
			}
			else {
				let vmsData = await queryVms({
					guid: brand.guid
				})
				if (vmsData) {
					const { results } = vmsData
					label = results[0]?.label || origin
					data = results[0].resources.find(element => element.type === 'thumbnail')
				}
				data?.uri ? setImageUri(data?.uri) : setImageUri(fallbackImage)
			}
		}
		getDataFromVMS();
	}, [configData])
	return (
		imageUri && <img className={classes} src={imageUri} alt={label} />
	)
}

LogoImageFromVMS.propTypes = {
	origin: PropTypes.string,
	classes: PropTypes.string
}

export default LogoImageFromVMS;